body {
  margin: 0;
  font-family: "Shabnam", "tahoma" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.prose code::before,
.prose code::after {
  content: none;
}

.prose code:not(pre code) {
  @apply px-1.5 py-0.5 text-gray-500 bg-gray-100 rounded dark:text-gray-300 dark:bg-gray-700;
}

@tailwind base;
@tailwind components;
@tailwind utilities;